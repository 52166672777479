<template>
  <section class="brackets" :style="{ width: windowSize.width }">
    <h2>Skupiny</h2>
    <nav>
      <a v-for="bracket in orderedBrackets" :key="bracket.id" :href="`#${bracket.id}`">{{ bracket.name }}</a>
    </nav>
    <ul>
      <li v-for="bracket in orderedBrackets" :key="bracket.id" :id="bracket.id">
        <h3>{{ bracket.name }}</h3>
        <RoundRobin v-if="bracket.type == 'RoundRobin'" :bracket="bracket" />
        <Elimination v-else :bracket="bracket" />
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { _ } from '@/store/tools'
import RoundRobin from './bracket-roundRobin.vue'
import Elimination from './bracket-elimination.vue'

export default {
  name: 'brackets',
  components: {
    RoundRobin,
    Elimination,
  },
  data: () => ({
    activeBracket: {},
  }),
  computed: {
    ...mapState(['windowSize']),
    orderedBrackets() {
      return _.order(this.$store.getters.bracketsFiltered, b => b.name)
    }
  },
}
</script>

<style lang="scss">
@import '../constants.scss';

.brackets {
  display: grid;

  nav {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    justify-content: center;
    overflow-x: auto;

    a {
      display: block;
      cursor: pointer;
      padding: $padding;

      &:not(:last-child) {
        border-right: 1px solid;
      }
    }
  }

  ul {
    display: grid;
    overflow-x: auto;
    grid-auto-flow: column;
    grid-template-columns: max-content;
    grid-gap: 2rem;
    padding: 0 2rem;

    li {
      display: grid;
      justify-content: center;
      align-content: start;
      padding-bottom: 2rem;
    }
  }
}
</style>
