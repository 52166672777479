<template>
  <footer>Samuel</footer>
</template>

<script>
export default {
  name: 'foot'
}
</script>

<style lang="scss">
footer {
  display: grid;
  padding-top: 1rem;
  justify-content: end;

  background-color: #000;
}
</style>