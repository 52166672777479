<template>
  <div class="elimination" :style="svgSize" v-html="bracketSvg">
  </div>
</template>
  
<script>
import { _ } from '@/store/tools'
import { getName } from '@/store/localization'

export default {
  name: 'elimination',
  props: {
    bracket: {},
  },
  data: () => ({
    sizeRatio: 0.6,
  }),
  computed: {
    bracketSvg() {
      var svg = this.$store.state.bracketGraphSvgs[this.bracket.id];
      if (svg != null) {
        var getTeamText = bt => {
          if (bt.teamId != null)
            return getName(bt.teamId);

          if (bt.resultType == "Looser" && bt.resultOfId != null) {
            var teamIndex = this.$store.state.matches[bt.resultOfId]?.index;
            return `Prohra z ${teamIndex + 1}`;
          }

          return "";
        }
        _.forEach(this.$store.state.bracketTeams, (bt, id) => svg = svg.replaceAll(`~~${id}~~`, getTeamText(bt)));
      }

      return svg;
    },
    svgSize() {
      const svg = this.bracketSvg
      const viewBoxIndex = svg.indexOf('viewBox="0 0 ') + 'viewBox="0 0 '.length
      const viewBoxEndIndex = svg.indexOf('"', viewBoxIndex)
      const viewBox = svg.substring(viewBoxIndex, viewBoxEndIndex)
      const [width, height] = viewBox.split(' ')
      return { width: `${width * this.sizeRatio}px`, height: `${height * this.sizeRatio}px` }
    },
  },
}
</script>
  
<style lang="scss">
@import '../constants.scss';

.elimination {
  padding: $padding;
  background-color: $box-back-color;
  border-radius: $padding;

  svg {
    .teamUnderline {
      stroke-width: 4px;
      stroke: black;
      stroke-opacity: 1;
      fill: none;
    }
    .matchLine {
      stroke-width: 2;
      stroke: black;
      stroke-opacity: 1;
      fill: none;
    }
    .matchIndex {
      font-size: 15px;
      font-weight: bold;
    }
  }
}
</style>
