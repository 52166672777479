<template>
  <div :class="{ notifications: true, hidden }">
    <div v-for="(notification, id) in notifications" :key="id" :class="notification.type">
      {{ notification.message }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'notifications',
  computed: {
    hidden() {
      return Object.keys(this.notifications).length == 0;
    },
    notifications() {
      return this.$store.state.notifications;
    }
  }
}
</script>

<style lang="scss">
@import '../constants.scss';

.notifications {
  position: absolute;
  top: 0;
  left: 50%;
  
  width: 30rem;
  margin-left: -15rem;

  &.hidden {
    display: none;
  }

  & > div {
    padding: $padding $padding-big;

    &:last-child {
      border-radius: 0 0 $padding $padding;
    }
    
    &.error {
      color: #000;
      background-color: hsl(0, 35%, 35%);
    }
    &.warning {
      color: #000;
      background-color: hsl(50, 35%, 35%);
    }
    &.success {
      color: #000;
      background-color: hsl(108, 35%, 35%);
    }
  }

}
</style>