const cz_short = {
  kids: 'RK',
  discovery: 'DR',
  adventure: 'AR',
  expedition: 'ER',
  royalRanger: 'RR',
  senior: 'SR',
  woman: 'Z',
}
const filters = {
  teams: "Týmy",
  fields: "Hřiště",
  judges: "Rozhodčí",
  categories: "Kategorie",
  outposts: "Hlídky",
  brackets: "Skupiny",
}
const cz_result = {
  Winner: "Vítěz",
  Looser: "Poražený",
}

export const getName = teamId => {
  if (teamId == null)
    return ''

  var [categoryId, teamNumber] = teamId.split('-')
  return `${cz_short[categoryId]}-${teamNumber}`
}

// with "Winner of 3"
export const getLongName = (bracketTeam, state) => {
  // should not happen
  if (bracketTeam == null)
    return ''

  // specific team
  if (bracketTeam.teamId != null)
    return getName(bracketTeam.teamId)

  // not specific team yet -> "Winner of 3"
  const inputMatch = state.matches[bracketTeam.resultOfId];
  return `${cz_result[bracketTeam.resultType]} z ${(inputMatch?.index ?? -5) + 1}`;
}

export const getFilter = filter => {
  if (filter == null)
    return ''

  if (filters[filter] != null)
    return filters[filter]

  return filter;
}