<template>
  <span v-if="bracketTeamY.id == bracketTeamX.id"><i class="fas fa-times"></i></span>
  <span v-else-if="match.isStarted" :class="{ playing: match.isStarted && match.winnerId == null }">
    {{ scoreX }}:{{ scoreY }}
  </span>
</template>
  
<script>
import { _ } from '@/store/tools'

export default {
  name: 'matchRoundRobin',
  props: {
    bracket: {},
    bracketTeamX: {},
    bracketTeamY: {},
  },
  computed: {
    teamAIsX() {
      return this.match.bracketTeamAId == this.bracketTeamX.id
    },
    match() {
      return _.first(this.$store.state.matches, m => (m.bracketTeamAId == this.bracketTeamX.id && m.bracketTeamBId == this.bracketTeamY.id) || (m.bracketTeamAId == this.bracketTeamY.id && m.bracketTeamBId == this.bracketTeamX.id))
    },
    scoreX() {
      return this.teamAIsX
        ? this.match.scoreA
        : this.match.scoreB
    },
    scoreY() {
      return this.teamAIsX
        ? this.match.scoreB
        : this.match.scoreA
    },
    teamXWin() {
      return this.bracketTeamX.teamId == this.match.winnerId
    },
    teamYWin() {
      return this.bracketTeamY.teamId == this.match.winnerId
    },
  }
}
</script>

<style lang="scss">
@import '../constants.scss';

.playing {
  padding: $padding;
  border-radius: $padding;
  background-color: $playing-back-color;
}
</style>