<template>
  <div :class="{ request: true, hidden }">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
</template>

<script>
export default {
  name: 'request',
  computed: {
    hidden() {
      return Object.keys(this.$store.state.requests).length == 0
    }
  }
}
</script>

<style lang="scss">
.request {
  position: absolute;
  top: 0;
  left: 50%;

  width: 10rem;
  height: 7rem;
  margin-left: -5rem;

  display: grid;
  align-content: center;
  justify-content: center;

  border-radius: 0 0 1rem 1rem;
  opacity: .8;
  color: #000;
  background-color: #666666;
  font-size: 3rem;

  &.hidden {
    display: none;
  }
}
</style>
