<template>
  <section class="fieldMap">
    <img src="map_fields.jpg" alt="mapa" width="1140" height="1280" />
    <svg
      xmlns:dc="http://purl.org/dc/elements/1.1/"
      xmlns:cc="http://creativecommons.org/ns#"
      xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
      xmlns:svg="http://www.w3.org/2000/svg"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
      viewBox="0 0 1140 1280"
      version="1.2"
      width="1140"
      height="1280"
    >
      <g v-for="(field) in fields" :key="field.id" @click="selectField(field)">
        <rect :x="field.x" :y="field.y" :width="field.width" :height="field.height" :transform="`rotate(${field.rotation} ${field.x + field.width / 2} ${field.y + field.height / 2})`" />
        <text :x="field.x + field.width / 2" :y="field.y + field.height / 2" text-anchor="middle" dominant-baseline="middle">{{ field.name }}</text>
        <title>{{ field.name }}</title>
      </g>
    </svg>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'fieldMap',
  computed: {
    ...mapState(['fields']),
  },
  methods: {
    selectField(field) {
      this.$store.commit('setFilterQuery', {
        query: field.name,
        filters: {
          teams: false,
          fields: true,
          judges: false,
          categories: false,
          outposts: false,
          brackets: false,
        }
      });
      this.$store.commit('toggleMap');
    },
  }
}
</script>
  
<style lang="scss">
@import '../constants.scss';

.fieldMap {
  position: fixed;
  display: grid;
  justify-content: center;
  overflow: auto;
  width: 100vw;
  height: 100vh;
  background-color: $back-color;
  z-index: 2;

  img, svg {
    grid-column: 1;
    grid-row: 2;
  }

  svg rect {
    fill: $playing-back-color;
  }
}
</style>
  