<template>
  <Request />
  <Notifications />
  <TopPanel />
  <main>
    <FieldMap v-if="showMap" />
    <Brackets />
    <List />
  </main>
  <Foot v-if="false" />
</template>

<script>
import { mapState } from 'vuex'
import Request from './components/request.vue'
import Notifications from './components/notifications.vue'
import TopPanel from './components/top-panel.vue'
import Brackets from './components/brackets.vue'
import FieldMap from './components/map.vue'
import List from './components/list.vue'
import Foot from './components/foot.vue'

export default {
  name: 'App',
  components: {
    Request,
    Notifications,
    TopPanel,
    Brackets,
    FieldMap,
    List,
    Foot,
  },
  computed: {
    ...mapState(['showMap']),
  },
  mounted() {
    window.onresize = () => this.$store.commit('resize');
    window.onresize();

    this.$store.dispatch('load');
  }
}
</script>

<style lang="scss">
@import './constants';

// reset
* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
}
html {
  font-size: 1.2rem;
}
a {
  color: inherit;
  text-decoration: none;
}
table {
  border-spacing: 0;
}

// master
body,
input {
  color: $text-color;
  background-color: $back-color;
}

h2, h3 {
  justify-self: center;
  margin: 2rem 1rem 0.5rem 1rem;
}

// main
#app {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-rows: min-content 1fr min-content;
}

main {
  overflow-y: scroll;
  overflow-x: hidden;

  padding-bottom: 2rem;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
::-webkit-scrollbar-track {
  background-color: #000;
  border-bottom: 1px solid hsl(0, 0%, 20%);
}
::-webkit-scrollbar-thumb {
  background-color: hsl(0, 0%, 20%);
  border-radius: 5px 5px 0 0;
}
</style>
