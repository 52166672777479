<template>
  {{ name }}
  <div v-if="includeOutpost" class="team_outpost">{{ outpost }}</div>
</template>

<script>
import { getLongName } from '@/store/localization'

export default {
  name: 'team',
  props: {
    bracketTeam: {},
    includeOutpost: { default: false },
  },
  computed: {
    name() {
      return getLongName(this.bracketTeam, this.$store.state);
    },
    outpost() {
      return this.$store.state.teams[this.bracketTeam.teamId] != null
        ? `${this.$store.state.teams[this.bracketTeam.teamId].outpostId}. PH`
        : "";
    }
  }
}
</script>

<style lang="scss">
.team_outpost {
  font-size: .7rem;
}
</style>